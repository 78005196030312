<template>
  <div id="app" class="uk-background-secondary uk-light">
    <Default></Default>
    <!--    <div class="uk-divider-icon"></div>-->
    <!--    <JSS></JSS>-->
  </div>
</template>

<script>
import Default from './components/Default.vue'
import JSS from './components/JSS.vue'

export default {
  name: 'App',
  components: {
    Default,
    JSS,
  },
}
</script>

<style lang="scss">
/*@import '~uikit/src/scss/uikit.scss';*/
@import "~reset-css/sass/reset.scss";

body {
  background-color: #222;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

#app {
  color: #2c3e50;
  padding-top: 60px;
}

.uk-form-stacked .uk-form-label {
  margin-bottom: 10px !important;
}
</style>
