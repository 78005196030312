import Vue from 'vue'
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import App from './App.vue'
import money from 'v-money'
// import VueGtm from '@gtm-support/vue2-gtm';

import '@vuikit/theme'
import VueTheMask from 'vue-the-mask'

Vue.use(Vuikit);
Vue.use(VuikitIcons);
Vue.use(VueTheMask);
/*Vue.use(VueGtm, {
  id: 'GTM-PWHPWMV',
  enabled: true,
});*/
Vue.use(money, {precision: 2});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
