<template>
 <div>
   <h1 class="uk-heading-medium uk-margin-medium-bottom">
     ЖСС депозит<br>
     (фикс 2 процента, ежеквартальная капитализация, <br>20% до 200 МРП на накопления за год - гос премия)
   </h1>
   <form @submit.prevent class="uk-form-stacked">
     <vk-grid style="align-items: flex-end;">
       <div>
         <label for="sum" class="uk-form-label">Сумма открытия</label>
         <input type="text" class="uk-input" id="sum" v-money="sumFormat" v-model="beginSum">
       </div>
       <div>
         <label for="term" class="uk-form-label">Срок(месяцев)</label>
         <input type="text" class="uk-input" id="term" v-mask="'###'" v-model="term" @change="onTermChange()">
       </div>
       <div>
         <label for="sum2" class="uk-form-label">Ежемесячное пополнение</label>
         <input type="text" class="uk-input" id="sum2" v-money="sumFormat" v-model="repl">
       </div>
       <div class="uk-light">
         <span class="uk-text-large">Итого: {{ total }}</span>
       </div>
     </vk-grid>
   </form>
 </div>
</template>

<script>
import trimSpaces from "../helpers/trimSpaces";
import toTriades from "../helpers/toTriades";
import dayjs from 'dayjs';

export default {
  name: 'Default',
  data() {
    return {
      beginSum: 0,
      term: 1,
      repl: 0,
      percentFormat: {
        thousands: ' ',
        precision: 2,
        min: 0,
      },
      sumFormat: {
        thousands: ' ',
        precision: 0,
        min: 0,
      },
    };
  },
  mounted() {
    console.log(dayjs());
  },
  computed: {
    total() {
      const mrp = 2917;
      const maxGovReward = mrp * 200;
      const percent = 2;
      const govRewardPercent = 20;
      const beginSum = trimSpaces(this.beginSum);
      const term = trimSpaces(this.term);
      const repl = trimSpaces(this.repl);
      let today = dayjs();
      let startYear = dayjs().year();
      let result = beginSum;
      let replForYear = 0;
      let dop = 0;
      for(let i = 0; i < term; i++) {
        if (i !== 0) {
          result += repl;
        }
        replForYear += repl;
        today.add(1, 'month');
        if (today.year() !== startYear) {
          const govReward = replForYear * ((govRewardPercent/100) / 12);
          result += govReward <= maxGovReward ? govReward : maxGovReward;
          startYear += 1;
        }
        if (i % 3 === 0) {
          result += (result + dop) * ((percent/100) / 4);
        }
      }
      return toTriades(Number(result).toFixed(2), true);
    },
  },
  methods: {
    onTermChange() {
      this.term = Math.max(1, this.term);
    },
  },
};
</script>
