export default (value, withDecimal) => {
  let separateValue = '';
  if (value || value === 0) {
    let stringNumber = value;
    if (typeof value === 'number') {
      stringNumber = value.toString();
    }
    const [number, decimal] = stringNumber.split('.');
    separateValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    let decimalNumber = decimal;
    if (withDecimal) {
      if (!decimal) decimalNumber = '00';
      else if (decimal.length === 1) decimalNumber = `${decimal}0`;
      else if (decimal.length > 2) decimalNumber = decimal.substring(0, 2);
    }
    if (decimalNumber) separateValue = `${separateValue},${decimalNumber}`;
  }
  return separateValue;
};
