<template>
  <div class="uk-light">
    <div class="uk-container">
      <h1 class="uk-text-large uk-margin-medium-bottom">
        Депозит с ежемесячной капитализацией
      </h1>
      <form @submit.prevent class="uk-form-stacked">
        <vk-grid style="align-items: flex-end;">
          <div>
            <label for="sum" class="uk-form-label">Сумма открытия</label>
            <input type="text" inputmode="numeric" class="uk-input" id="sum" v-money="sumFormat" v-model="beginSum">
          </div>
          <div>
            <label for="term" class="uk-form-label">Срок({{ termInYear ? 'лет' : 'месяцев' }})</label>
            <input type="text" inputmode="numeric" class="uk-input" id="term" v-mask="'###'" v-model="term" @change="onTermChange()">
          </div>
          <div>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
              <label>
                <input class="uk-checkbox" type="checkbox" v-model="termInYear">
                Срок в годах
              </label>
            </div>
          </div>
        </vk-grid>
        <vk-grid style="align-items: flex-end;">
          <div>
            <label for="percent" class="uk-form-label">Процент</label>
            <input type="text" inputmode="numeric" class="uk-input" id="percent" v-money="percentFormat" v-model="percent">
          </div>
          <div>
            <label for="sum2" class="uk-form-label">Ежемесячное пополнение</label>
            <input type="text" inputmode="numeric" class="uk-input" id="sum2" v-money="sumFormat" v-model="repl">
          </div>
          <div>
            <span class="uk-text-large">Итого: {{ totalShow }}</span>
          </div>
        </vk-grid>
        <vk-grid>
          <span class="uk-text-large">Ежемесячное вознаграждение на итоговую сумму: {{ monthRewardTotal }}</span>
        </vk-grid>
      </form>
      <!--<div style="display: flex;justify-content: center;">
        <button class="uk-button" @click="sendGA()">test</button>
      </div>-->
    </div>
  </div>
</template>

<script>
import trimSpaces from "../helpers/trimSpaces";
import toTriades from "../helpers/toTriades";

export default {
  name: 'Default',
  data() {
    return {
      beginSum: 0,
      term: 1,
      percent: '13.20',
      repl: 0,
      termInYear: false,
      percentFormat: {
        thousands: ' ',
        precision: 2,
        min: 0,
      },
      sumFormat: {
        thousands: ' ',
        precision: 0,
        min: 0,
      },
    };
  },
  computed: {
    monthRewardTotal() {
      const total = trimSpaces(`${this.total}`);
      if (!total) return 0;
      const result = total*(this.percent/100/12);
      return this.toShow(result);
    },
    total() {
      const beginSum = trimSpaces(this.beginSum);
      const percent = trimSpaces(this.percent);
      let term = trimSpaces(this.term);
      if (this.termInYear) term = term * 12;
      const repl = trimSpaces(this.repl);
      let result = beginSum;
      for(let i = 0; i < term; i++) {
        if (i !== 0) {
          result += repl;
        }
        result += result * (percent/100) / 12;
      }
      return result;
      // const result = beginSum * Math.pow((1 + (((percent / 100) + repl) / 12) ), term);
    },
    totalShow() {
      return this.toShow(this.total);
    },
  },
  methods: {
    toShow(val) {
      return toTriades(Number(val).toFixed(2), true);
    },
    onTermChange() {
      this.term = Math.max(1, this.term);
    },
    sendGA() {
      this.$gtm.trackEvent({
        category: 'TtesTs',
        action: 'click',
        label: 'dq',
      });
      this.$gtm.trackEvent({
        action: 'TtesTs',
        label: 'dq1',
      });
    },
  },
};
</script>
